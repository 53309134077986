import React, { useState, useEffect } from 'react'
import { StyleSheet, Text, TouchableOpacity, TextInput, View, Dimensions } from 'react-native'

import IMask from 'imask'
import { useMutation } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { toast } from 'react-toastify'
import Loading from '../components/Loading'
import { SEND_USER_PHONE } from '../gqls'
import { SmartCaptcha } from '@yandex/smart-captcha';
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import Window from './Window'

const phoneMask = IMask.createMask({
    mask: '+{7}(000)000-00-00'
})

const Auth = (props) => {
    const navigation = useNavigation()

    const [phone, setPhone] = useState('')
    const [captchaToken, setCaptchaToken] = useState('')
    const [loading, setLoading] = useState(false)
    const [isNightTime, setNightTime] = useState(false)
    const [sendUserPhoneMutation] = useMutation(SEND_USER_PHONE, {
        onCompleted: ({ sendUserPhone }) => {
            setLoading(false)
            if (sendUserPhone) {
                if (window.location.search != "") {
                    navigation.navigate('Code', {
                        phone: sendUserPhone.phone,
                        preview: props.preview,
                        returnUrl: window.location.href
                    })
                } else {
                    navigation.navigate('Code', {
                        phone: sendUserPhone.phone,
                        preview: props.preview,
                    })
                }
            } else {
                toast('Капча введена неверно')
            }
        },
    })

    useEffect(() => {
        dayjs.extend(utc);
        dayjs.extend(tz);
        const currentTime = dayjs().tz("Asia/Yakutsk").format('HH:mm');
        const startNight = '21:00';
        const endNight = '09:00';
        // Если текущее время больше или равно 21:00 или меньше 09:00, то это ночное время
        setNightTime(currentTime >= startNight || currentTime < endNight);
    }, [])

    const onChangeText = (value) => {
        const maskedValue = phoneMask.resolve(value)
        setPhone(maskedValue)
    }

    const onChangeToken = (value) => {
        setCaptchaToken(value)
    }

    const onSubmit = async () => {
        const unmaskedValue = phoneMask.unmaskedValue
        if (unmaskedValue.length === 11) {
            setLoading(true)
            await sendUserPhoneMutation({
                variables: {
                    data: {
                        phone: unmaskedValue,
                        captchaToken: captchaToken
                    }
                }
            })
        } else {
            toast('Неправильно введен номер телефона')
        }
    }

    return (
        <Window style={styles.container}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Text style={[styles.title, { marginTop: windowWidth > 768 ? 80 : 0 }]}>Войти</Text>
                    <Text style={styles.text}>Войдите в аккаунт и начните бронировать домики.</Text>
                    <TextInput style={styles.input} value={phone} onChangeText={onChangeText} keyboardType='phone-pad' placeholder='Номер телефона' placeholderTextColor='#667085' />
                    {isNightTime ? (
                        <SmartCaptcha sitekey="ysc1_qcw54FHQFJgoxOokCqselF9RCm2WE8aBe9MM9bnFe584bfcd" onSuccess={onChangeToken} />) : ''
                    }
                    <TouchableOpacity onPress={onSubmit} style={[styles.button, { marginTop: 20 }]}>
                        <Text style={styles.buttonText}>Войти</Text>
                    </TouchableOpacity>
                </>
            )}
        </Window>
    )
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 32,
    },
    title: {
        color: '#101828',
        fontSize: 30,
        fontWeight: '500',
        marginBottom: 8
    },
    text: {
        color: '#667085',
        marginBottom: 24
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#101828',
        fontWeight: '500'
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        marginBottom: 32
    },
})

export default Auth