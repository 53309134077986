import React from 'react'
import {
    View,
    StyleSheet,
    Text,
    TouchableOpacity,
    ScrollView,
    Linking
} from 'react-native'

import { useNavigation } from '@react-navigation/native'

const Footer = () => {
    const navigation = useNavigation()

    const openURL = (url) => Linking.openURL(url)

    return (
        <View style={styles.container}>
            <View style={styles.line} />
            <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={{ marginHorizontal: 16 }}>
                <Text style={styles.text}>© 2025 DomikiYkt</Text>
                <Text style={[styles.dot, styles.text]}>·</Text>
                <TouchableOpacity onPress={() => navigation.navigate('About')}>
                    <Text style={styles.text}>О нас</Text>
                </TouchableOpacity>
                <Text style={[styles.dot, styles.text]}>·</Text>
                <TouchableOpacity onPress={() => openURL('https://domikiykt.ru/documents/termsOfUse.pdf')}>
                    <Text style={styles.text}>Пользовательское соглашение</Text>
                </TouchableOpacity>
                <Text style={[styles.dot, styles.text]}>·</Text>
                <TouchableOpacity onPress={() => openURL('https://domikiykt.ru/documents/policy.pdf')}>
                    <Text style={styles.text}>Политика конфиденциальности</Text>
                </TouchableOpacity>
                <Text style={[styles.dot, styles.text]}>·</Text>
                <TouchableOpacity onPress={() => openURL('https://domikiykt.ru/documents/offer.pdf')}>
                    <Text style={styles.text}>Публичная оферта</Text>
                </TouchableOpacity>
                <Text style={[styles.dot, styles.text]}>·</Text>
                <TouchableOpacity onPress={() => navigation.navigate('Requisites')}>
                    <Text style={styles.text}>Реквизиты</Text>
                </TouchableOpacity>
                <Text style={[styles.dot, styles.text]}>·</Text>
                <TouchableOpacity onPress={() => navigation.navigate('Support')}>
                    <Text style={styles.text}>Клиентам</Text>
                </TouchableOpacity>
                <Text style={[styles.dot, styles.text]}>·</Text>
                <TouchableOpacity onPress={() => navigation.navigate('Partner')}>
                    <Text style={styles.text}>Партнерам</Text>
                </TouchableOpacity>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        paddingBottom: 24
    },
    line: {
        height: 1,
        backgroundColor: '#D0D5DD',
        marginBottom: 24,
        marginHorizontal: 16
    },
    dot: {
        marginHorizontal: 8,
        fontSize: 16
    },
    text: {
        color: '#667085'
    }
})

export default Footer